import React from "react";

class RandomText extends React.Component {
  state = {
    texts: [],
    maxDisplayedTexts: 100,
    zoomFactor: 1,
    printSmallTextCount: 0,
  };

  componentDidMount() {
    const { text, timeout, maxDisplayedTexts } = this.props;
    this.setState({ maxDisplayedTexts });
    this.addTextToQueue(text, timeout);

    window.addEventListener("resize", this.handleZoomChange);
    this.handleZoomChange();
  }

  componentWillUnmount() {
    // Entferne den Event-Listener, wenn die Komponente unmontiert wird
    window.removeEventListener("resize", this.handleZoomChange);
  }

  handleZoomChange = () => {
    // Aktualisiere den Zoom-Faktor
    const zoomFactor = window.devicePixelRatio || 1;
    this.setState({ zoomFactor });
  };

  getRandomPosition = (textWidth, textHeight) => {
    const windowWidth = window.innerWidth;
    const windowHeight = window.innerHeight;

    const position = {
      x: Math.floor(Math.random() * (windowWidth - textWidth)),
      y: Math.floor(Math.random() * (windowHeight - textHeight)),
    };

    return position;
  };

  generateRandomColor = () => {
    const randomColor = `rgba(${Math.random() * 255}, ${Math.random() * 255}, ${
      Math.random() * 255
    }, 1)`;
    document.body.style.backgroundColor = randomColor;
  };

  addTextToQueue = (text, timeout) => {
    const texts = [...this.state.texts];
    if (texts.length >= this.state.maxDisplayedTexts) {
      texts.shift();
    }

    const getRandomSize = () =>
      Math.floor((Math.random() * 500) / this.state.zoomFactor) +
      400 / this.state.zoomFactor;
    const getRandomRotation = () => Math.floor(Math.random() * 360);
    var size = getRandomSize();
    if (this.state.printSmallTextCount <= 10) {
      const printSmallTextCount = this.state.printSmallTextCount++;
      this.setState({ printSmallTextCount });
    } else {
      size = (window.innerWidth * 0.5) / this.state.zoomFactor;
      const printSmallTextCount = 0;
      this.setState({ printSmallTextCount });
    }
    const rotation = getRandomRotation();

    const tempDiv = document.createElement("div");
    tempDiv.innerText = text;
    tempDiv.style.fontSize = `${size}px`;
    tempDiv.style.fontWeight = "bold";
    tempDiv.style.position = "absolute";
    document.body.appendChild(tempDiv);

    const textWidth = tempDiv.offsetWidth;
    const textHeight = tempDiv.offsetHeight;

    document.body.removeChild(tempDiv);

    const position = this.getRandomPosition(textWidth, textHeight);

    const style = {
      position: "absolute",
      top: position.y,
      left: position.x,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      //   width: "100%",
      //   height: "100%",
      fontFamily: "Arial, sans-serif",
      fontWeight: "bold",
      fontSize: `${size}px`,
      transform: `rotate(${rotation}deg)`,
      color: `rgba(${Math.random() * 255}, ${Math.random() * 255}, ${
        Math.random() * 255
      }, 1)`,
    };

    texts.push(
      <div key={Date.now()} style={style}>
        {text}
      </div>
    );
    this.setState({ texts });
    this.generateRandomColor();

    setTimeout(() => this.addTextToQueue(text, timeout), timeout);
  };

  render() {
    return (
      <div
        id="random-text-container"
        style={{
          position: "relative",
          width: "100vw",
          height: "100vh",
          overflow: "hidden",
          maxWidth: "100vw",
          maxHeight: "100vh",
        }}
      >
        {this.state.texts}
      </div>
    );
  }
}

export default RandomText;
