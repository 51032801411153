import { Hidden } from "@mui/material";
import React, { useState, useEffect } from "react";

const BouncingImage = ({ id, src, speed, onClick, data }) => {
  const { parentCoords, parentDirection } = data;
  const [position, setPosition] = useState({
    x: parentCoords.x,
    y: parentCoords.y,
  });
  const [direction, setDirection] = useState({
    x: parentDirection.x,
    y: parentDirection.y,
  });
  const [zoomFactor, setZoomFactor] = useState(window.devicePixelRatio || 1);
  const [imgSpeed, setImgSpeed] = useState(speed);

  const imagewidth = 800;

  useEffect(() => {
    const handleZoomChange = () => {
      setZoomFactor(window.devicePixelRatio || 1);
    };

    const moveImage = () => {
      setPosition((prevPosition) => {
        const newX = prevPosition.x + (imgSpeed * direction.x) / zoomFactor;
        const newY = prevPosition.y + (imgSpeed * direction.y) / zoomFactor;
        return { x: newX, y: newY };
      });
    };

    const handleBounce = () => {
      const windowWidth = window.innerWidth;
      const windowHeight = window.innerHeight;
      const imageWidthZoom = imagewidth / zoomFactor; // Adjust the image size with zoomFactor
      const imageheightZoom = imagewidth / 1.344 / zoomFactor; // Adjust the image size with zoomFactor

      if (position.x + imageWidthZoom >= windowWidth) {
        setDirection((prevDirection) => ({
          x: -1,
          y: prevDirection.y,
        }));
      }
      if (position.x <= 0) {
        setDirection((prevDirection) => ({
          x: 1,
          y: prevDirection.y,
        }));
      }
      if (position.y + imageheightZoom >= windowHeight) {
        setDirection((prevDirection) => ({
          x: prevDirection.x,
          y: -1,
        }));
      }
      if (position.y <= 0) {
        setDirection((prevDirection) => ({
          x: prevDirection.x,
          y: 1,
        }));
      }
    };

    const intervalId = setInterval(() => {
      moveImage();
      handleBounce();
    }, 1000 / 60); // Move at 60 fps

    window.addEventListener("resize", handleZoomChange);

    return () => {
      clearInterval(intervalId);
      window.removeEventListener("resize", handleZoomChange);
    };
  }, [position, direction, imgSpeed, zoomFactor]);

  const increaseSpeed = () => {
    setImgSpeed((prevSpeed) => prevSpeed + 1);
  };

  return (
    <div
      style={{
        position: "absolute",
        width: "100%",
        height: "100%",
        overflow: Hidden,
      }}
    >
      <div
        style={{
          position: "relative", // Füge position: "relative" hinzu
          zIndex: 1, // Setze zIndex auf 1, um das überlagernde Element vor das darunterliegende zu legen
        }}
      >
        <div
          style={{
            fontSize: 20,
            position: "fixed",
            bottom: 0,
            right: 0,
            margin: 20,
            fontFamily: "Arial, sans-serif",
            fontWeight: "bold",
            pointerEvents: "auto", // Setze pointerEvents auf "auto", um Klicks in diesem Bereich zu ermöglichen
          }}
        >
          <p>{imgSpeed}</p>
        </div>
        <img
          className="bouncing-image"
          src={src}
          alt="Moving Image"
          onClick={() => onClick(position, direction)}
          style={{
            transform: `translate(${position.x}px, ${position.y}px)`,
            width: `${imagewidth / zoomFactor}px`, // Adjust the image size with zoomFactor
            height: "auto",
          }}
        />
      </div>

      {/* <video
        className="bouncing-image"
        src={src}
        alt="Moving Image"
        onClick={increaseSpeed}
        style={{
          transform: `translate(${position.x}px, ${position.y}px)`,
          width: `${imagewidth / zoomFactor}px`, // Adjust the image size with zoomFactor
          height: "auto",
        }}
        type="video/mp4"
        autoPlay
        loop
        muted
      /> */}
    </div>
  );
};

export default BouncingImage;
