import React from "react";
import RandomText from "./RandomText";
import schiggenboergerImg from "./schiggenboerger.jpg";
import fordFucus from "./fordfocus.jpg";
import mario from "./mario-toad.mp4";
import janBirthday from "./janbirthday.png";
import BouncingImage from "./bouncing_image/BouncingImage";
import { Hidden } from "@mui/material";

class PreviewPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      bouncingImages: [
        {
          id: 1,
          src: janBirthday,
          speed: 2,
          data: {
            parentCoords: {
              x: 0,
              y: 0,
            },
            parentDirection: {
              x: 0,
              y: 0,
            },
          },
        },
      ],
    };
  }

  addBouncingImage = (imageId, position, direction) => {
    const newId = this.state.bouncingImages.length + 1;
    const parentImg = this.state.bouncingImages.find(
      (item) => item.id === imageId
    );
    console.log("pos:" + position.x);
    console.log("ParentID: " + imageId);
    console.log(parentImg);

    const data = {
      parentCoords: {
        x: position.x,
        y: position.y,
      },
      parentDirection: this.getRandomDirection(direction),
    };
    console.log("data: " + data);
    const newBouncingImages = [
      ...this.state.bouncingImages,
      {
        id: newId,
        src: janBirthday,
        speed: 2,
        data: data,
      },
    ];
    this.setState({ bouncingImages: newBouncingImages });
    console.log("Clicked image id:", imageId);
  };

  handleImageClick = (imageId, position, direction) => {
    this.addBouncingImage(imageId, position, direction);
  };

  getRandomDirection = (direction) => {
    var directionX = this.generateRandomNumber();
    var directionY = this.generateRandomNumber();

    while (direction.x == directionX && direction.y == directionY) {
      directionX = this.generateRandomNumber();
      directionY = this.generateRandomNumber();
    }

    var parentDirection = {
      x: directionX,
      y: directionY,
    };
    return parentDirection;
  };

  generateRandomNumber = () => {
    return Math.random() < 0.5 ? -1 : 1;
  };

  render() {
    return (
      <div
        style={{
          position: "absolute",
          width: "100%",
          height: "100%",
          overflow: Hidden,
        }}
      >
        <div
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            overflow: Hidden,
          }}
        >
          {/* RandomText Komponente */}
          {
            <RandomText
              text="Schiggenbörger"
              timeout={300}
              maxDisplayedTexts={75}
            />
          }
        </div>
        <div
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            width: "100%",
            height: "100%",
            overflow: Hidden,
          }}
        >
          {/* BouncingImage Komponente */}
          {this.state.bouncingImages.map((bouncingImage) => (
            <BouncingImage
              key={bouncingImage.id}
              src={bouncingImage.src}
              speed={bouncingImage.speed}
              onClick={(position, direction) =>
                this.handleImageClick(bouncingImage.id, position, direction)
              }
              data={bouncingImage.data}
            />
          ))}
        </div>
      </div>
    );
  }
}

export default PreviewPage;
